import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@progress/kendo-react-form';
import { Stepper } from '@progress/kendo-react-layout';
import { Input, type InputChangeEvent, Switch } from '@progress/kendo-react-inputs';
import { DialogActionsBar, Dialog } from '@progress/kendo-react-dialogs';
import { useHistory } from 'react-router-dom';

import languages from './languages.json'
import type { Theme } from '../../../interfaces/surveyInterfaces/themeTypes';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { parseJwt } from '../helpers/decodeJWT/decodeJWT';
import { useFetchTags } from '../customHooks/useFetchTags';
import { BaseDropDownList, BaseMultiSelect } from "../Inputs";
import ProjectActionButtons from '../ProjectActionButtons/ProjectActionButtons';
import { fetchPostJson as addSurvey, fetchGetJsonData, rsAppUrl } from '../../../services/services';

type Props = {
  token: string;
  projectId: string;
  handleClose: () => void;
}

type Tags = {
  id: string;
  name: string;
}

type Languages = {
  text: string;
  value: string;
}

type FormData = {
  description?: string;
  name?: string;
  tags?: Tags[];
  themeId?: Theme;
  languages?: Languages[];
}

const items = [{ label: "General settings" }, { label: "Languages and style" }]

export default function AddSurveyModal({ token, projectId, handleClose }: Props) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<FormData>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [stepperValue, setStepperValue] = useState(0);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [DCVersion, setDCVersion] = useState(1);
  const tags = useFetchTags(token);
  const history = useHistory<History>();

  // For now, only show the DC version toggle for the two customers that have access to it (Walr Staging and Walr Production customers)
  const parsedToken = parseJwt(token);
  const customerId = parsedToken[`${rsAppUrl}/customerid`];
  const showDCVersionToggle = !!(customerId === '024f7442-48b8-4b31-9b07-92421f0e602f' || customerId === '3ef11f3f-290d-4a64-925c-34ae02f863ce');

  useEffect(() => {
    setThemes([]);
    if (stepperValue === 1) {
      fetchGetJsonData<Theme[]>(DCVersion === 2 ? 'su/themes' : 'themes', token)
        .then((res) => {
          setThemes(res)
        }).catch((errorMessage: string) => {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: errorMessage } })
        })
    }
  }, [dispatch, token, stepperValue, DCVersion])

  const onChangeHandler = (event: InputChangeEvent) => {
    if (event.target.name && event.target.value) {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value
      })
    }

  }
  const handleChange = (e: { value: number }) => {
    setStepperValue(e.value)
  }

  const onSubmitHandler = () => {
    const body = {
      name: formData?.name,
      description: formData?.description,
      languages: formData?.languages?.map(el => el.value),
      tags: formData?.tags,
      dataCollectionVersion: DCVersion,
      ...(DCVersion === 2 && { themeId: formData?.themeId?.id }),
      ...(DCVersion === 1 && { themeSettings: { id: formData?.themeId?.id } })
    }

    if (formData?.languages?.length === 0 || formData?.name === '') {
      setErrorMessage(`${formData?.languages?.length === 0 && formData?.name === '' ? "Survey name and languages" : formData?.languages?.length === 0 ? "Languages" : "Survey name"} cannot be empty`)
    } else {
      setIsLoading(true)
      addSurvey(`su/projects/${projectId}/surveys`, token, body)
        .then((res: TODO) => {
          setIsLoading(false)
          if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          } else if (Object.entries(res).length !== 0) {
            handleClose()
            const location = history.location.pathname
            dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: res.surveyId, name: formData?.name } })
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The survey has been created successfully' } })
            history.push({ pathname: `${location}/${res.surveyId}/survey-builder` })
          }
        })
    }
  }

  const listNoDataRender = () => {
    return (
      <h5 className="text-muted text-center">
        <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
        <br />
        <span>No tags created yet</span>
      </h5>
    );
  }

  return (
    <Dialog title="Create survey" onClose={handleClose} width={650} height={600}>
      <Form
        onSubmit={onSubmitHandler}
        render={(_formRenderProps) => (
          <div className="p-4">
            <div className="pb-3">
              <Stepper
                className='create-survey-steps'
                items={items}
                value={stepperValue}
                onChange={handleChange}
              />
            </div>
            {stepperValue === 0 ?
              <div className="d-flex flex-column">
                <Input
                  name="name"
                  className="w-100 mb-2"
                  label="Name"
                  value={formData?.name}
                  onChange={onChangeHandler}
                />

                <Input
                  className="w-100 mb-2"
                  name="description"
                  label="Description"
                  value={formData?.description}
                  onChange={onChangeHandler}
                />
                <BaseMultiSelect
                  data={tags}
                  value={formData?.tags}
                  label="Tags"
                  textField="name"
                  dataItemKey="id"
                  name="tags"
                  allowCustom={true}
                  listNoDataRender={listNoDataRender}
                  onChange={onChangeHandler}
                />
                {showDCVersionToggle && (
                  <div className="d-flex justify-content-between align-items-center form-control mt-5 py-5">
                    <p className="mr-3 mb-0 text-primary">Use Data Collection V2</p>
                    <Switch checked={DCVersion === 2} onChange={(e) => setDCVersion(e.value ? 2 : 1)} />
                  </div>
                )}
              </div> :
              <div className="d-flex flex-column">
                <BaseMultiSelect
                  data={languages}
                  className="w-100 mb-2"
                  textField="text"
                  onChange={onChangeHandler}
                  label="Languages"
                  value={formData?.languages}
                  name="languages"
                />
                <BaseDropDownList
                  data={themes}
                  textField="name"
                  label="Themes"
                  value={themes.find(theme => theme.id === formData?.themeId?.id)}
                  className="w-100 mb-2"
                  onChange={onChangeHandler}
                  name="themeId" />

              </div>}
            {
              errorMessage &&
              <ErrorMessage
                type="alert"
                errorMessage={errorMessage}
              />
            }
          </div>
        )}>
      </Form>
      <DialogActionsBar>
        <ProjectActionButtons
          submitAction={'Create'}
          onHide={handleClose}
          stepperValue={stepperValue}
          steps={items.length}
          isLoading={isLoading}
          handleChange={handleChange}
          onSubmitHandler={onSubmitHandler}
          formRef={undefined}
        />
      </DialogActionsBar>
    </Dialog>
  )
}